import { breakpoints } from "styles";
import styled from "styled-components";
import Image from "next/legacy/image";
import { IStyledTitleProps, IStyledSpanProps } from "./testimonial.types";

export const StyledTestimonialCard = styled.div<{ $width: string }>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 20px 24px 24px;
  max-width: 373px;
  @media ${breakpoints.mobile} {
    padding: 16px;
    gap: 24px;
    border-radius: 16px;
  }
  @media ${breakpoints.laptop} {
    display: flex;
    flex-direction: row;
    gap: 20px;
    min-height: 200px;
    height: fit-content;
    width: 340px;
  }
`;

export const StyledAvatar = styled(Image)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue[600]};
`;

export const StyledHeader = styled.header`
  display: flex;
  margin: 8px 0 5px;
`;

export const StyledImageWrapper = styled.div`
  height: fit-content;
`;
export const StyledHeaderContentWrapper = styled.div`
  margin-left: 8px;
`;
export const StyledContent = styled.div`
  width: 100%;
`;

export const StyledTitle = styled("div")<IStyledTitleProps>`
  width: 100%;
  color: ${({ theme }) => theme.colors.neutral[900]};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  font-weight: ${({ $isBold }) => $isBold && "bold"};
  line-height: 22px;
  text-align: initial;
  @media ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes["md"]};
  }
`;

export const StyledDescription = styled(StyledTitle)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.neutral[600]};
`;

export const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledSpan = styled("span")<IStyledSpanProps>`
  display: inline-flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 22px;
  font-weight: ${({ $isBold }) => $isBold && "bold"};
  text-align: initial;
  padding: 0 10px 10px 10px;
  border-radius: 8px;
  height: 10px;
`;

export const StyledHeaderLabel = styled(StyledSpan)`
  background-color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.colors.yellow[500] : theme.colors.brand[500]};
  color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.colors.brand[500] : theme.colors.white};
  padding: 10px;
  font-weight: bold;
`;

export const StyledFooterSpan = styled(StyledSpan)`
  background-color: unset;
  color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.colors.black : theme.colors.neutral[600]};
  font-size: ${({ theme }) => theme.fontSizes.sm};
`;

export const StyledCardWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const StyledVideoWrapper = styled.div`
  align-self: center;
  border-radius: 4px;
`;

export const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  @media ${breakpoints.mobile} {
    gap: 16px;
  }
`;

export const StyledFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
`;
