import { FAQ } from "./faq-block.types";

export const FAQData = [
  {
    title: "Apa itu CoLearn?",
    desc: "CoLearn adalah aplikasi belajar online dengan fitur Bimbel Online dan Latihan. CoLearn hadir sebagai aplikasi belajar untuk membantu murid SD, SMP, SMA untuk bisa menguasai mata pelajaran Matematika, Fisika, dan Kimia.Selain itu, ada video pembahasan untuk mengerjakan PR/tugas yang bisa diakses lewat website colearn.id dan YouTube channel CoLearn."
  },
  {
    title: "Di mana lokasi Bimbel CoLearn?",
    desc: "CoLearn merupakan bimbel online dengan interaksi 2-arah melalui HP atau laptop. Murid akan belajar langsung dengan guru dan teman lainnya, setiap minggu sesuai jadwal yang telah dipilih. "
  },
  {
    title: "Apakah materi yang diajar di CoLearn sama seperti di sekolah?",
    desc: "Materi CoLearn menggunakan kurikulum pemerintah, Kurikulum Merdeka atau Kurikulum 2013. Materi di CoLearn terkadang lebih lambat atau lebih cepat dibandingkan sekolah, sehingga urutannya tidak akan selalu sama."
  },
  {
    title: "Berapa jumlah murid dalam 1 (satu) kelas?",
    desc: "Jumlah murid di kelas di SD adalah maksimal 30 murid, jumlah murid di kelas SMP atau SMA adalah maksimal 70 murid, agar semua murid bisa mendapatkan akses kepada guru yang kompeten dengan penalaran kuat dengan harga terjangkau."
  },
  {
    title: "Apakah hanya ada mata pelajaran Matematika?",
    desc: "Saat ini CoLearn fokus pada pemahaman Matematika. Namun selain Matematika, terdapat juga mata pelajaran Fisika, Kimia, dan IPA, berbasis Kurikulum 2013 dan Kurikulum Merdeka. "
  },
  {
    title: "Bagaimana contoh kelas Bimbel online di CoLearn?",
    desc: "Berikut contoh kelas CoLearn: <a href='https://youtu.be/wh6NQysUdGo?si=8_O0TOalEKeCLtyQ'>https://youtu.be/wh6NQysUdGo?si=8_O0TOalEKeCLtyQ</a>"
  },
  {
    title:
      "Apakah aplikasi bimbel CoLearn menyediakan layanan bimbel offline atau kelas privat?",
    desc: "CoLearn tidak menyediakan bimbel offline. CoLearn memastikan kegiatan belajar mengajar yang efektif juga bisa dilakukan di rumah, sehingga murid dan orang tua bisa menghemat waktu dan biaya. "
  }
];
export const BimbelFaqData = [
  {
    title: "Bimbel online CoLearn tuh apa, sih?",
    desc: "Bimbel CoLearn adalah bimbel online terbaik yang bantu kamu cepet ngerti Matematika, Fisika, Kimia. Jadi kamu tetep punya waktu buat lakuin hal yang kamu suka."
  },
  {
    title: "Apa keuntungan ikut bimbel online CoLearn?",
    desc: "Ada banyak keuntungan yang bisa kamu dapetin: <ul><li>Tetep punya waktu buat jalanin hobi yang disuka dan dapet nilai matematika bagus. </li> <li>Belajarnya di kelas online, 1 jam tiap kelas. </li> <li>Belajar online dari rumah pakai aplikasi belajar CoLearn. </li> <li>Gurunya bisa bantu bikin pelajaran matematika, fisika, kimia cepet masuk otak.</li> <li>Ada garansi uang kembali 100% kalau nggak cocok.</li> </ul>"
  },
  {
    title: "Gimana cara daftar bimbel online CoLearn?",
    desc: "Daftarnya dari website atau aplikasi belajar CoLearn."
  },
  {
    title: "Harga bimbel online CoLearn berapa, Kak?",
    desc: "Harga bimbel online CoLearn 95.000 per bulan. Kamu juga bisa langganan sekaligus satu semester atau satu tahun, pastinya harganya jadi lebih murah."
  },
  {
    title: "Kalo cara bayarnya bisa pakai apa aja?",
    desc: "Banyak, tergantung CoFriends nyamannya pakai yang mana. Bisa Bank Transfer (BCA, BRI, Mandiri, dan BNI), e-wallet (OVO & GoPay), retail (Alfamart), dan kartu kredit (VISA & Mastercard). CoLearn juga menyediakan opsi pembayaran bulanan paket Bimbel mulai dari 95.000 per bulan."
  },
  {
    title: "Kita belajar mata pelajaran apa aja sih di Bimbel CoLearn?",
    desc: "Di bimbel online CoLearn, kamu bisa belajar Matematika, Fisika, dan Kimia. Ada Kurikulum 2013 dan Kurikulum Merdeka juga."
  },
  {
    title: "Berapa lama aku harus ikutan bimbel onlinenya?",
    desc: "Agar hasil maksimal, kurikulum di Bimbel CoLearn dirancang untuk kegiatan belajar selama 1 semester. Tapi kamu bisa cobain dulu 1 bulan. Kalau cocok, bisa lanjut bulanan atau lanjut sekaligus 1 semester atau lebih."
  },
  {
    title: "Siapa tutor online yang ngajar?",
    desc: "Mereka adalah tutor terbaik yang sudah lolos seleksi ketat dan berpengalaman mengajar online, lulusan terbaik dari universitas ternama, dan selalu kasih apresiasi dan motivasi."
  },
  {
    title: "Cara ngajar tutornya CoLearn gimana, sih? Aku kepo",
    desc: "Cara ngajarnya pake games seru, yang pasti bikin mood belajarmu jadi naik."
  }
];

export const FAQStructuredData: (data: FAQ[]) => Record<string, unknown> = (
  data = FAQData
) => {
  const FAQSchema = data.map((FAQ) => ({
    "@type": "Question",
    name: FAQ.title,
    acceptedAnswer: {
      "@type": "Answer",
      text: FAQ.desc
    }
  }));
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: FAQSchema
  };
};
