import React from "react";
import {
  StyledAvatar,
  StyledFooter,
  StyledFooterSpan,
  StyledImageWrapper,
  StyledTitle,
  StyledTestimonialCard,
  StyledText,
  StyledFooterWrapper
} from "./testimonial-null-video-card.styles";
import { ITestimonialCardProps } from "./testimonial.types";

export const TestimonialNullVideoCard: React.FC<ITestimonialCardProps> = ({
  data,
  width
}) => {
  const { image, name, relation, title } = data;
  return (
    <StyledTestimonialCard $width={width}>
      <StyledText>
        <StyledTitle $isBold $lineClamp={3}>{`“${title}”`}</StyledTitle>
        <StyledFooter>
          <StyledImageWrapper>
            {
              <StyledAvatar
                src={image}
                alt="avatar"
                width="50"
                height="50"
                objectFit="cover"
              />
            }
          </StyledImageWrapper>
          <StyledFooterWrapper>
            <StyledFooterSpan $isBold $isHighlighted>
              {name}
            </StyledFooterSpan>
            <StyledFooterSpan $isHighlighted>{relation}</StyledFooterSpan>
          </StyledFooterWrapper>
        </StyledFooter>
      </StyledText>
    </StyledTestimonialCard>
  );
};
