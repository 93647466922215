import styled from "styled-components";
import { breakpoints, sizes } from "styles";
import { Container } from "styles/common/container";

export const FAQBlockWrapper = styled(Container)`
  padding: 0 0 40px 0;

  @media ${breakpoints.tablet} {
    max-width: ${sizes.faqSectionWidth};
    margin: 0 auto;
  }
`;

export const FAQBlockTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 1.5;
  text-align: center;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["4xl"]};
  }
`;
export const FAQBlockContainer = styled(Container)``;

export const FAQListContainer = styled.ol`
  list-style: none;
  padding: 0 20px;
  margin: 0;
`;

export const FAQListItem = styled.li<{ $isHidden: boolean }>`
  display: ${({ $isHidden }) => ($isHidden ? "none" : "")};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  }
`;

export const FAQTitle = styled.summary`
  list-style: none;
  cursor: pointer;
  outline: none;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  line-height: 1.5;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutral[900]};

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  }
  &::-webkit-details-marker {
    display: none;
  }

  &::marker {
    display: none;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;

  &:after {
    border-right: 5px solid;
    border-bottom: 5px solid;
    content: "";
    min-width: 12px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    border-color: currentColor;
    border-radius: 3px;
    transition: all 0.3s;
  }
`;
export const FAQContent = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.neutral[800]};
  line-height: 1.5;
  margin-top: 8px;
  padding: 0 0 16px 0;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }
`;

export const FAQItem = styled.details`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  }

  &[open] ${FAQTitle} {
    color: ${({ theme }) => theme.colors.brand[500]};

    &:after {
      margin-left: 5px;
      transform: rotate(225deg);
    }
  }
`;

export const ShowAllCTAContainer = styled.div`
  margin-top: 20px;

  @media ${breakpoints.tablet} {
    margin-top: 42px;
  }

  & > * {
    margin-left: auto;
    margin-right: auto;
  }
`;
