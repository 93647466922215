import {
  StyledCardWrapper,
  StyledCarouselWrapper,
  StyledReviewSection,
  StyledTitle,
  ReviewContainer
} from "./review-section.styles";
import { IReviewSectionProps } from "./review-section.types";
import { TestimonialCard } from "../testimonial-card/testimonial-card";
import { IComponentWithSource } from "types/common/common.types";

export const ReviewSection: React.FC<
  IReviewSectionProps & IComponentWithSource
> = (props) => {
  const { testimonials, title, width } = props;
  return (
    <StyledReviewSection>
      <ReviewContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledCarouselWrapper>
          {testimonials.map((obj) => (
            <StyledCardWrapper key={obj.id}>
              <TestimonialCard width={width} data={obj} />
            </StyledCardWrapper>
          ))}
        </StyledCarouselWrapper>
      </ReviewContainer>
    </StyledReviewSection>
  );
};
