import { breakpoints } from "styles";
import styled from "styled-components";
import Image from "next/legacy/image";
import PlayButton from "public/assets/play-button.png";
import { IStyledTitleProps, IStyledSpanProps } from "./testimonial.types";

export const StyledTestimonialCard = styled.div<{ $width: string }>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 14px 24px 24px;
  @media ${breakpoints.laptop} {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: fit-content;
    width: 690px;
  }

  @media ${breakpoints.mobile} {
    gap: 24px;
    padding: 20px 0;
  }
`;

export const StyledAvatar = styled(Image)`
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue[600]};
`;
export const ImageContainer = styled(Image)`
  width: 300px;
  height: 150px;
`;
export const StyledHeader = styled.header`
  display: flex;
  margin: 8px 0 5px;
`;

export const StyledImageWrapper = styled.div`
  height: fit-content;
`;
export const StyledHeaderContentWrapper = styled.div`
  margin-left: 8px;
`;
export const StyledContent = styled.div`
  width: 100%;
`;

export const StyledTitle = styled("div")<IStyledTitleProps>`
  width: fit-content;
  color: ${({ theme }) => theme.colors.neutral[900]};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["lg"]};
  font-weight: ${({ $isBold }) => $isBold && "bold"};
  line-height: 22px;
  text-align: initial;
  @media ${breakpoints.mobile} {
    font-size: ${({ theme }) => theme.fontSizes["md"]};
  }
`;

export const StyledDescription = styled(StyledTitle)`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.neutral[600]};
`;

export const StyledFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledSpan = styled("span")<IStyledSpanProps>`
  display: inline-flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: 22px;
  font-weight: ${({ $isBold }) => $isBold && "bold"};
  text-align: initial;
  padding: 0 10px 10px 10px;
  border-radius: 8px;
  height: 10px;
`;

export const StyledHeaderLabel = styled(StyledSpan)`
  background-color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.colors.yellow[500] : theme.colors.brand[500]};
  color: ${({ $isHighlighted, theme }) =>
    $isHighlighted ? theme.colors.brand[500] : theme.colors.white};
  padding: 10px;
  font-weight: bold;
`;

export const StyledFooterSpan = styled(StyledSpan)`
  background-color: unset;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 20px;
`;

export const StyledCardWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const StyledVideoWrapper = styled.div`
  position: relative;
  align-self: center;
  cursor: pointer;
  iFrame {
    border-radius: 4px;
  }
`;

export const StyledVideoThumbnail = styled.div`
  width: 280px;
  height: 150px;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  @media ${breakpoints.mobile} {
    width: 300px;
  }
`;

export const StyledPlayButtonContainer = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  background: url(${PlayButton.src}) no-repeat center center;
  background-size: contain;
  border: none;
  cursor: pointer;
`;

export const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.white};
  @media ${breakpoints.mobile} {
    gap: 16px;
    padding: 0 16px;
  }
`;

export const StyledFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
`;
