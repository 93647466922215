import styled from "styled-components";
import { breakpoints } from "styles";
import { Container } from "styles/common/container";

export const StyledReviewSection = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue[50]};
`;

export const StyledTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["3xl"]};
  line-height: 24px;
  margin-bottom: 40px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutral[900]};
  text-align: center;
  @media ${breakpoints.mobile} {
    width: 300px;
    font-size: ${({ theme }) => theme.fontSizes["2xl"]};
    line-height: 42px;
    margin: 0;
  }
`;

export const ReviewContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  @media ${breakpoints.mobile} {
    padding: 40px 30px;
  }
`;

export const StyledCardWrapper = styled.div`
  margin: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 10px;
  box-shadow: 0px 4px 9px rgba(110, 116, 134, 0.05);
  border-radius: 12px;
`;

export const StyledCarouselWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
`;

export const BimbelCTALink = styled.a`
  margin: 32px auto;
  display: block;
  max-width: 328px;
  background-color: ${({ theme }) => theme.colors.brand[500]};
  color: ${({ theme }) => theme.colors.white};
  padding: 12px 24px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  border-radius: 6px;
  text-align: center;
`;
