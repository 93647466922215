import React from "react";
import trackEvent from "services/mixpanel/mixpanel";
import { FAQData, FAQStructuredData } from "./faq-block.data";
import {
  FAQItem,
  FAQBlockContainer,
  FAQBlockWrapper,
  FAQContent,
  FAQListContainer,
  FAQListItem,
  FAQTitle,
  FAQBlockTitle
} from "./faq-block.styles";
import { FAQ, FAQBlockProps } from "./faq-block.types";
import { eventNames } from "services/mixpanel/events";
import { useBoolean } from "hooks/use-boolean/use-boolean";
const NUMBER_OF_FAQ_VISIBLE_INITIALLY = 7;

const FAQBlock: React.FC<FAQBlockProps> = ({
  data = FAQData,
  eventSource,
  showExpandCTA = false,
  showHeading = true
}) => {
  const showAllFAQ = useBoolean();
  const handleFAQItemClick = (faq: FAQ, index: number) => {
    trackEvent({
      eventName: eventNames.FAQExpandButtonClicked,
      payload: {
        source: eventSource || "",
        faqTitle: faq.title,
        faqDesc: faq.desc,
        faqPosition: index + 1
      }
    });
  };
  return (
    <FAQBlockWrapper>
      <FAQBlockContainer>
        {showHeading && (
          <FAQBlockTitle>Pertanyaan seputar CoLearn</FAQBlockTitle>
        )}
        <FAQListContainer>
          {data.map((faq, index) => (
            <FAQListItem
              key={`faq-list-item-${index}`}
              $isHidden={
                showExpandCTA &&
                !showAllFAQ &&
                index >= NUMBER_OF_FAQ_VISIBLE_INITIALLY
              }
            >
              <FAQItem
                onClick={() => handleFAQItemClick(faq, index)}
                open={index === 0}
              >
                <FAQTitle>{faq.title}</FAQTitle>
                <FAQContent dangerouslySetInnerHTML={{ __html: faq.desc }} />
              </FAQItem>
            </FAQListItem>
          ))}
        </FAQListContainer>
      </FAQBlockContainer>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(FAQStructuredData(data))
        }}
      />
    </FAQBlockWrapper>
  );
};

export { FAQBlock };
