import React, { useState } from "react";
import {
  StyledAvatar,
  StyledFooter,
  StyledFooterSpan,
  StyledImageWrapper,
  StyledTitle,
  StyledTestimonialCard,
  StyledVideoWrapper,
  StyledText,
  StyledFooterWrapper,
  StyledVideoThumbnail,
  StyledPlayButtonContainer,
  ImageContainer
} from "./testimonial-card.styles";
import { TestimonialNullVideoCard } from "./testimonial-null-video-card";
import { ITestimonialCardProps } from "./testimonial.types";
import { VideoModal } from "components/video-modal/video-modal";
import VideoThumb from "public/assets/vdo-frame.jpg";

export const TestimonialCard: React.FC<ITestimonialCardProps> = ({
  data,
  width
}) => {
  const { image, name, relation, title, videoUrl } = data;
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoClick = () => {
    setIsVideoPlaying(true);
  };

  return !videoUrl ? (
    <TestimonialNullVideoCard data={data} width={width} />
  ) : (
    <StyledTestimonialCard $width={width}>
      <StyledVideoWrapper onClick={handleVideoClick}>
        <StyledVideoThumbnail>
          <ImageContainer src={VideoThumb} alt="video-thumbnail" />
        </StyledVideoThumbnail>
        <StyledPlayButtonContainer />
      </StyledVideoWrapper>
      {isVideoPlaying && (
        <VideoModal
          onClose={() => setIsVideoPlaying(false)}
          videoUrl={videoUrl}
        />
      )}
      <StyledText>
        <StyledTitle $isBold $lineClamp={3}>{`“${title}”`}</StyledTitle>
        <StyledFooter>
          <StyledImageWrapper>
            {
              <StyledAvatar
                src={image}
                alt="avatar"
                width="50"
                height="50"
                objectFit="cover"
              />
            }
          </StyledImageWrapper>
          <StyledFooterWrapper>
            <StyledFooterSpan $isBold $isHighlighted>
              {name}
            </StyledFooterSpan>
            <StyledFooterSpan $isHighlighted>{relation}</StyledFooterSpan>
          </StyledFooterWrapper>
        </StyledFooter>
      </StyledText>
    </StyledTestimonialCard>
  );
};
